import React from 'react'

import { AccountPageLayout } from '@guiker/account-components'
import { webappRoutes } from '@guiker/account-shared'
import { InvoiceContent, useInvoiceContext } from '@guiker/payment-app-components'
import { Flex } from '@guiker/react-framework'

import { useAuthenticationContext } from '../../hooks'
import { useT } from '../../i18n'

export const ViewInvoiceScreen: React.FC = () => {
  const { invoice, isLoading } = useInvoiceContext()
  const { user } = useAuthenticationContext()
  const { tMain: tNav } = useT({ screenName: 'sideMenu.navItem' })

  const breadcrumbItems = [
    { label: tNav('wallet'), to: '../../' },
    { label: tNav('payments'), to: webappRoutes.root.wallet().payments().path },
    { label: tNav('paymentHistory'), to: webappRoutes.root.wallet().payments().history().path },
    { label: invoice?.id },
  ]

  return (
    <AccountPageLayout breadcrumbItems={breadcrumbItems} isLoading={isLoading}>
      <Flex flexDirection='column' maxWidth={900}>
        <InvoiceContent user={user} />
      </Flex>
    </AccountPageLayout>
  )
}
