import React from 'react'

import { Flex, PSmall } from '@guiker/react-framework'

export const Label: React.FC<{ content: string; chip?: React.ReactNode }> = ({ content, chip }) => {
  return (
    <Flex gap={1} alignItems='center'>
      {!!chip && chip}
      <PSmall mb={0}>{content}</PSmall>
    </Flex>
  )
}
