import React, { useEffect, useState } from 'react'

import { SearchAddress } from '@guiker/address-autocomplete-component'
import { Address as BaseAddress } from '@guiker/base-entity'
import { SsnTextField } from '@guiker/masked-text-field'
import {
  AccountHolderType,
  Address,
  AuthenticatedApi,
  getPayoutConfigs,
  initializeAddress,
  PayoutMethod,
  PayoutMethodType,
  SupportedCountries,
  SupportedStates,
} from '@guiker/payout-shared'
import {
  Collapse,
  DatePicker,
  DeepPartial,
  Dropdown,
  Flex,
  FormSection,
  FormSectionGroup,
  get,
  NumericInput,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useFormContext,
  useGetInputProps,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/yup-util'

import { useEditLinkContext } from '../../context'
import { useAuthenticationContext, usePayoutMethodNestedContext } from '../../hooks'
import { getLocalizedCountry, getLocalizedState, useTranslation } from '../../i18n'
import { EditButton } from '../EditButton'

type AddressFormProps = {
  defaultValue: DeepPartial<Address>
  formPrefix: string
  showNumberInput?: boolean
  readOnly?: boolean
  isCountryDisabled?: boolean
  onYearAtAddressChange?: (value?: number) => void
  schema: yup.ObjectSchema
  schemaPrefix?: string
  label?: string
  required?: boolean
}

type AccountHolderFormContentProps = {
  name?: string
  defaultValue?: DeepPartial<PayoutMethod>
  addresses?: DeepPartial<Address & { id?: string }>[]
  readOnly?: boolean
  onYearAtAddressChange?: (index?: number, value?: number) => void
  title?: string
}

const FormContentAddress: React.FC<AddressFormProps> = ({
  formPrefix,
  defaultValue,
  onYearAtAddressChange = () => {},
  readOnly,
  isCountryDisabled = true,
  showNumberInput = true,
  schema,
  schemaPrefix,
  label,
  required,
}) => {
  const { t } = useTranslation(['payout', 'errors'])
  const formContext = readOnly ? undefined : useFormContext()
  const [supportedStates, setSupportedStates] = useState([])
  const [isAddressSearched, setIsAddressSearched] = useState(!!defaultValue.streetNumber && !!defaultValue.streetName)
  const hasError = Object.keys(get(formContext?.formState.errors, formPrefix) || {}).length > 0
  const country =
    isCountryDisabled || readOnly
      ? defaultValue.country
      : useWatch({ name: `${formPrefix}.country`, defaultValue: defaultValue.country })

  const supportedCountries = Object.values(SupportedCountries).map((country) => ({
    label: getLocalizedCountry(t, { countryCode: country }),
    value: country,
  }))

  const inputProps = useGetInputProps({
    namespaces: 'common',
    tPrefix: 'common:address',
    formPrefix,
    readOnly,
    defaultValue,
    schema,
    schemaPrefix,
  })

  useEffect(() => {
    country &&
      setSupportedStates(
        Object.values(SupportedStates[country]).map((state: string) => ({
          label: getLocalizedState(t, { countryCode: country, stateCode: state }),
          value: state,
        })),
      )
    if (showNumberInput && formContext) {
      formContext?.setValue(`${formPrefix}.yearsAtAddress`, defaultValue?.yearsAtAddress || 3)
    }
  }, [country])

  const onAddressChange = (address: BaseAddress) => {
    formContext?.setValue(
      formPrefix,
      { ...defaultValue, ...address, streetName: address.street },
      { shouldDirty: true, shouldValidate: true },
    )
    setIsAddressSearched(true)
  }

  return (
    <Flex gap={4} flexDirection='column'>
      {!readOnly && (
        <SearchAddress
          readOnly={readOnly}
          error={hasError}
          errorMessage={hasError && t('errors:address.search.required')}
          onChange={onAddressChange}
          countryRestrictions={[country]}
          label={label}
          required={required}
        />
      )}
      <Collapse in={isAddressSearched}>
        <TwoColumnsGridLayout>
          <TextField {...inputProps('suiteNumber')} defaultValue={defaultValue?.suiteNumber} />
          <TextField {...inputProps('streetNumber')} defaultValue={defaultValue?.streetNumber} />
          <TextField {...inputProps('streetName')} defaultValue={defaultValue?.streetName} />
          <TextField {...inputProps('city')} defaultValue={defaultValue?.city} />
          <TextField {...inputProps('postalCode')} defaultValue={defaultValue?.postalCode} />
          <Dropdown
            {...inputProps('country')}
            options={supportedCountries}
            disabled={isCountryDisabled}
            defaultValue={defaultValue?.country}
          />
          <Dropdown {...inputProps('state')} options={supportedStates} defaultValue={defaultValue?.state} />

          {showNumberInput && (
            <NumericInput
              label={t(`payout:payoutMethod.accountHolder.address.yearsAtAddress`)}
              name={`${formPrefix}.yearsAtAddress`}
              defaultValue={defaultValue?.yearsAtAddress || 3}
              readOnly={readOnly}
              onChange={onYearAtAddressChange}
              min={1}
            />
          )}
        </TwoColumnsGridLayout>
      </Collapse>
    </Flex>
  )
}

const FormContentBusiness: React.FC<
  React.PropsWithChildren & {
    defaultValue: DeepPartial<PayoutMethod>
    readOnly: boolean
  }
> = ({ children, defaultValue, readOnly }) => {
  const { t } = useTranslation('payout')
  const formPrefix = 'accountHolder.business'
  const tPrefix = `payout:payoutMethod.${formPrefix}`

  const inputProps = useGetInputProps({
    namespaces: 'payout',
    formPrefix,
    tPrefix,
    readOnly,
    defaultValue,
    schema: AuthenticatedApi.Schemas.businessSchema(true),
    schemaPrefix: '',
  })

  const representativeType = readOnly
    ? defaultValue.accountHolder.business.representativeType
    : useWatch({ name: `${formPrefix}.representativeType`, defaultValue: 'OWNER' })

  const country = getPayoutConfigs(defaultValue?.type)?.country

  return (
    <FormSectionGroup>
      <FormSection title={t(`${tPrefix}.type.name`)}>
        <RadioGroup
          required={inputProps('type').required}
          readOnly={inputProps('type').readOnly}
          name={inputProps('type').name}
          defaultValue={inputProps('type').defaultValue}
          options={[
            { label: t(`${tPrefix}.type.soleprop`), value: 'SOLEPROP' },
            { label: t(`${tPrefix}.type.partnership`), value: 'PARTNERSHP' },
            { label: t(`${tPrefix}.type.corp`), value: 'CORP' },
          ]}
        />
      </FormSection>
      <FormSection title={t(`${tPrefix}.businessInformation`)}>
        <TwoColumnsGridLayout>
          <TextField {...inputProps('name')} />
          <TextField {...inputProps('legalName')} />
          <TextField {...inputProps('phoneNumber')} />
          <TextField {...inputProps('taxNumber')} />
        </TwoColumnsGridLayout>
        <FormContentAddress
          formPrefix={`${formPrefix}.address`}
          label={t(`${tPrefix}.businessAddress`)}
          required
          defaultValue={
            defaultValue.accountHolder.business?.address?.country === country
              ? defaultValue.accountHolder.business?.address
              : initializeAddress(defaultValue?.type)
          }
          readOnly={readOnly}
          showNumberInput={false}
          schema={AuthenticatedApi.Schemas.businessSchema(true)}
          schemaPrefix='address'
        />
      </FormSection>
      <FormSection title={t(`${tPrefix}.businessRepresentative`)}>
        <TwoColumnsGridLayout perItemGridColumn={{ 2: { md: { span: 2 } } }}>
          <Dropdown
            {...inputProps('representativeType')}
            label={t(`${tPrefix}.representativeTitle.type`)}
            defaultValue={representativeType}
            options={[
              { label: t(`${tPrefix}.representativeTitle.owner`), value: 'OWNER' },
              { label: t(`${tPrefix}.representativeTitle.executive`), value: 'EXECUTIVE' },
              { label: t(`${tPrefix}.representativeTitle.director`), value: 'DIRECTOR' },
              { label: t(`${tPrefix}.representativeTitle.other`), value: 'OTHER' },
            ]}
          />
          <TextField
            {...inputProps('representativeTitle', { dependency: { representativeType } })}
            label={t(`${tPrefix}.representativeTitle.label`)}
          />
          {children}
        </TwoColumnsGridLayout>
      </FormSection>
    </FormSectionGroup>
  )
}

const AccountHolderFormContent: React.FC<AccountHolderFormContentProps> = ({
  defaultValue,
  addresses = [],
  onYearAtAddressChange = () => {},
  readOnly = false,
  title,
}) => {
  const { t } = useTranslation('payout')
  const { user } = useAuthenticationContext()
  const formPrefix = 'accountHolder'
  const tPrefix = `payout:payoutMethod.${formPrefix}`
  const { decryptSSN } = usePayoutMethodNestedContext()
  const shouldRequireIdNumber = defaultValue.type === PayoutMethodType.US_BANK_ACCOUNT
  const { editUrl } = useEditLinkContext({ shouldThrowIfUndefined: false }) ?? {}

  const inputProps = useGetInputProps({
    namespaces: 'payout',
    formPrefix,
    tPrefix,
    readOnly,
    defaultValue,
    schema: AuthenticatedApi.Schemas.accountHolderSchema,
    schemaPrefix: '',
  })

  const individualAccountHolderFields = (
    <TwoColumnsGridLayout>
      <TextField {...inputProps('firstName')} />
      <TextField {...inputProps('lastName')} />
      <DatePicker
        {...inputProps('dateOfBirth')}
        disableFuture
        errorMessages={{
          max: t('payout:payoutMethod.errors.accountHolderAge'),
        }}
      />
      <TextField
        {...inputProps('emailAddress')}
        type='email'
        disabled
        defaultValue={inputProps('emailAddress')?.defaultValue ?? user.emailAddress}
      />
      <TextField {...inputProps('phoneNumber')} type='phone' />
      {readOnly ? (
        <SsnTextField
          defaultValue={defaultValue?.accountHolder?.ssn}
          name='ssn'
          readOnly={true}
          revealText={decryptSSN}
        />
      ) : (
        <TextField
          {...inputProps('ssn')}
          label={t(`payout:payoutMethod.${formPrefix}.${shouldRequireIdNumber ? 'ssn' : 'sin'}`)}
          required={shouldRequireIdNumber}
        />
      )}
    </TwoColumnsGridLayout>
  )

  const country = getPayoutConfigs(defaultValue?.type)?.country

  return (
    <FormSection title={title} topActions={readOnly && <EditButton url={editUrl} />}>
      {defaultValue?.accountHolderType === AccountHolderType.COMPANY ? (
        <FormContentBusiness defaultValue={defaultValue} readOnly={readOnly}>
          {individualAccountHolderFields}
        </FormContentBusiness>
      ) : (
        <FormSection>{individualAccountHolderFields}</FormSection>
      )}
      {addresses?.map((address, index) => (
        <FormSection key={index} title={index === 0 ? t(`${tPrefix}.currentAddress`) : t(`${tPrefix}.previousAddress`)}>
          <FormContentAddress
            formPrefix={`${formPrefix}.addresses[${index}]`}
            defaultValue={
              index === 0
                ? address?.country === country
                  ? address
                  : initializeAddress(defaultValue?.type)
                : initializeAddress(defaultValue?.type)
            }
            readOnly={readOnly}
            isCountryDisabled={index === 0}
            onYearAtAddressChange={(value) => onYearAtAddressChange(index, value)}
            schema={AuthenticatedApi.Schemas.updateAccountHolderSchema}
          />
        </FormSection>
      ))}
    </FormSection>
  )
}

export { AccountHolderFormContent }
