import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { Card, Collapse, Flex, FormSection4, makeStyles, P, PBig, PSmall } from '@guiker/components-library'
import { ChevronDownIcon } from '@guiker/icons'
import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { math } from '@guiker/lodash'
import { money } from '@guiker/money'
import { PropertySaleListing } from '@guiker/property-sale-shared'

import { useTranslation } from '../../i18n'

type TotalReturnProps = {
  listing: PropertySaleListing
}

type RowProps = {
  label: string
  value: string
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 12,
    height: 12,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconCollapsed: {
    transform: 'rotate(0)',
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
  clickable: {
    cursor: 'pointer',
  },
}))

const IntermediateRow: React.FC<RowProps> = ({ label, value }) => {
  return (
    <Flex justifyContent='space-between' width={'100%'}>
      <PSmall mb={0}>{label}</PSmall>
      <P mb={0}>{value}</P>
    </Flex>
  )
}

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ label, value, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  return (
    <Flex flexDirection='column'>
      <Flex
        gap={2}
        alignItems='center'
        onClick={() => setIsOpen((curr) => !curr)}
        className={clsx(children && classes.clickable)}
      >
        <Flex justifyContent='space-between' width={'100%'} mr={children ? 0 : 3.5}>
          <P mb={0}>{label}</P>
          <PBig mb={0}>{value}</PBig>
        </Flex>
        {children && (
          <ChevronDownIcon className={clsx(classes.icon, isOpen ? classes.iconExpanded : classes.iconCollapsed)} />
        )}
      </Flex>

      {children && (
        <Collapse in={isOpen}>
          <Flex gap={1} flexDirection='column' ml={4} mt={1} mr={3.5}>
            {children}
          </Flex>
        </Collapse>
      )}
    </Flex>
  )
}

const FinancialDetails: React.FC<TotalReturnProps> = () => {
  const { t } = useTranslation('main-propertySale')
  const { investmentResults, investmentAssumptions, currency } = useInvestmentAssumptionContext()
  const tPrefix = 'main-propertySale:screens.listingScreen'
  const purchaseTPrefix = 'common-propertySale:listing.assumptions.purchase'

  return (
    <>
      <Card
        shadow='light'
        textAlign='left'
        p={3}
        radius={0}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Flex width={'100%'} gap={6} flexDirection='column'>
          <FormSection4 title={t(`${tPrefix}.tab1.sections.financialDetails.costs`)}>
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.totalProjectCost`)}
              value={money.fromAmount(investmentResults.totalProjectCost, currency).toString('onlySymbol', true)}
            >
              <IntermediateRow
                label={t(`${tPrefix}.tab1.sections.financialDetails.purchasePrice`)}
                value={money
                  .fromAmount(investmentAssumptions.purchase.price.amount, currency)
                  .toString('onlySymbol', true)}
              />
              {investmentResults.computedPurchaseTaxes?.map((tax) => (
                <IntermediateRow
                  key={tax.type}
                  label={t(`${purchaseTPrefix}.taxes.${tax.jurisdiction}.${tax.type}`)}
                  value={money.fromAmount(tax.computedValue, currency).toString('onlySymbol', true)}
                />
              ))}
              {Object.values(investmentAssumptions.purchase.costs || {})
                ?.filter((cost) => cost.value.amount.value > 0)
                .map((cost) => (
                  <IntermediateRow
                    key={cost.type}
                    label={t(`${purchaseTPrefix}.costs.${cost.jurisdiction}.${cost.type}`)}
                    value={money.fromAmount(cost.value.amount.value, currency).toString('onlySymbol', true)}
                  />
                ))}
            </Row>
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.targetEquity`)}
              value={money.fromAmount(investmentResults.targetEquity, currency).toString('onlySymbol', true)}
            />
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.loanAmount`)}
              value={money.fromAmount(investmentResults.loanAmount, currency).toString('onlySymbol', true)}
            />
          </FormSection4>
          <FormSection4 title={t(`${tPrefix}.tab1.sections.financialDetails.results`)}>
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.irr`)}
              value={`${math.decimal.round(investmentResults.irr * 100, 2)}%`}
            />
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.returnMultiple`)}
              value={`${math.decimal.round(investmentResults.returnMultiple * 100, 2)}%`}
            />
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.estimatedSalesPrice`)}
              value={money.fromAmount(investmentResults.estimatedSalesPrice, currency).toString('onlySymbol', true)}
            />
            <Row
              label={t(`${tPrefix}.tab1.sections.financialDetails.npv`)}
              value={money.fromAmount(investmentResults.npv, currency).toString('onlySymbol', true)}
            />
          </FormSection4>
        </Flex>
      </Card>
    </>
  )
}

export { FinancialDetails }
