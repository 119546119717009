import React, { useState } from 'react'

import { hasRentPaymentsPlanSupport } from '@guiker/booking-shared'
import { getPayInMethodLabels, PayInMethodModals, PayInMethodSelector } from '@guiker/payment-app-components'
import {
  getCurrencyFromSupportedCountry,
  getSupportedCountryFromCurrency,
  PayInMethodType,
} from '@guiker/payment-shared'
import {
  ApiForm,
  ButtonContainer,
  ButtonWithLoader,
  Divider,
  Flex,
  Form,
  KeyValuePair,
  Note,
  PageLayout,
  PSmall,
  SecondaryButton,
  SuccessDialog,
  useNavigate,
} from '@guiker/react-framework'
import { compact, CurrencyISO, money } from '@guiker/shared-framework'

import { PaymentBreakdown } from '../../components'
import {
  PayInMethodContextProvider,
  useAppContext,
  useBookingPaymentApiClient,
  usePayInMethodContext,
  useTakeoverContext,
} from '../../hooks'
import { useTranslation } from '../../i18n'

const FormButton: React.FC = () => {
  const { t } = useTranslation('common')
  const { onClose } = useTakeoverContext()

  const apiClient = useBookingPaymentApiClient()
  const { bookingId, refetchBooking, bookingPayment, userContribution, isSingleTenant } = useAppContext()
  const { region, selected: selectedPayInMethod } = usePayInMethodContext()
  const [isOpen, setIsOpen] = useState(false)

  const info = getPayInMethodLabels({ payInMethod: selectedPayInMethod, t, region })
  const amount = isSingleTenant ? bookingPayment.total.amount : userContribution.amount

  const onSubmit = async () => {
    const { id, legacyId, type } = selectedPayInMethod
    const payInMethodId = hasRentPaymentsPlanSupport({ bookingPayment }) ? id : legacyId?.toString() ?? id

    return apiClient
      .updateOneBookingPaymentContribution({
        pathParams: { bookingId },
        payload: {
          ...(isSingleTenant ? { amount } : undefined),
          payInMethodType: type,
          payInMethodId,
        },
      })
      .then(() => refetchBooking())
      .then(() => setIsOpen(true))
  }

  return (
    <div>
      <ApiForm formName={'BookingPaymentContributionMethodForm'} onSubmit={onSubmit}>
        {({ isLoading, isSuccess }) => (
          <ButtonWithLoader isLoading={isLoading} disabled={isLoading || isSuccess || !selectedPayInMethod}>
            {t('submit')}
          </ButtonWithLoader>
        )}
      </ApiForm>
      <SuccessDialog
        open={isOpen}
        text={t('success.title')}
        onClose={onClose}
        subtitle={t('success.description')}
        buttonProps={{ onClick: onClose, children: t('close') }}
      >
        <Flex flexDirection='column' gap={2}>
          <KeyValuePair
            keyName={t('success.amount')}
            value={money.fromAmount(amount, getCurrencyFromSupportedCountry(region)).toString()}
          />
          <KeyValuePair keyName={t('success.with')} value={info.brandName} />
        </Flex>
      </SuccessDialog>
    </div>
  )
}

const PayInMethod: React.FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { bookingPayment, userContribution, isSingleTenant } = useAppContext()
  const { directDebitEFT, directDebitPAD, directDebitACH } = { ...bookingPayment?.eligibility }
  const { payInMethodId } = { ...userContribution }

  const currency = {
    isCAD: bookingPayment.total.currency === CurrencyISO.CAD,
    isUSD: bookingPayment.total.currency === CurrencyISO.USD,
  }
  const region = getSupportedCountryFromCurrency(bookingPayment.total.currency)
  const allowedTypes = compact([
    PayInMethodType.CREDIT_CARD,
    directDebitEFT && !directDebitPAD && currency.isCAD ? PayInMethodType.DIRECT_DEBIT_EFT : undefined,
    directDebitPAD && currency.isCAD ? PayInMethodType.DIRECT_DEBIT_PAD : undefined,
    directDebitACH && currency.isUSD ? PayInMethodType.DIRECT_DEBIT_ACH : undefined,
  ])

  const handleBackClick = () => navigate('../')

  return (
    <PageLayout maxWidth={900 as const} title={t('addYourPaymentInfo')} hasButtonContainer>
      <Flex flexDirection='column' gap={2}>
        <Note statusColor='alabaster' title={t('YouWontBeChargedYet')}>
          {t('onlyChargeYouIf')}
        </Note>
        <Divider />
        <PayInMethodContextProvider region={region} allowedTypes={allowedTypes} selectedId={payInMethodId}>
          <Flex flexDirection='column' gap={2}>
            <Form formName='BookingPaymentPayInMethod'>
              <PayInMethodSelector />
            </Form>
            <PSmall>{t('ifYourBookingIsConfirmed')}</PSmall>
            <Divider />
            <PaymentBreakdown />
          </Flex>
          <ButtonContainer>
            {!isSingleTenant && <SecondaryButton onClick={handleBackClick}>{t('back')}</SecondaryButton>}
            <FormButton />
          </ButtonContainer>
          <PayInMethodModals />
        </PayInMethodContextProvider>
      </Flex>
    </PageLayout>
  )
}

export { PayInMethod }
