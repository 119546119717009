import React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { ListingBanner } from '@guiker/base-listing-components'
import { AuthenticatedApi, ListingInquirySource, ListingInquiryStatus } from '@guiker/listing-inquiry-shared'
import { ApiForm, Flex, PageLayout, PageMetaTags, yupResolver } from '@guiker/react-framework'
import { ListingInquiryCard } from '@guiker/rental-listing-inquiry-components'
import { getListingAvailability, isListingOperator, RentalListing } from '@guiker/rental-listing-shared'

import { NeighbourhoodComponent } from '../../components'
import { OperatorComponent, SimilarListings } from '../../components'
import { ListingContent } from '../../components/ListingContent'
import { ListingScreenDivider } from '../../components/ListingScreenDivider'
import {
  ListingInquiryContextProvider,
  useAuthenticationContext,
  useListingInquiryContext,
  usePublicListingContext,
  useTranslation,
} from '../../hooks'
import { useLocationFromPath } from '../../services'
import { getListingBreadcrumbItemsFromLocation } from '../../utils/breadcrumb-helper'

const ListingScreenContent: React.FC<{
  listing: RentalListing
  isRentedOut: boolean
  isUnlisted: boolean
  userIsOperator: boolean
}> = ({ listing, userIsOperator, isRentedOut, isUnlisted }) => {
  const { user: currentUser } = useAuthenticationContext()
  const shouldShowInquiryCard = !isRentedOut && !userIsOperator

  const {
    setOpenInquiryCardModal,
    checkAuthentication,
    selectedPriceOptionId,
    createListingInquiry,
    setListingInquiry,
    setInquirySubmitted,
    roommates,
  } = useListingInquiryContext()

  const defaultValues = {
    rentalOptionId: selectedPriceOptionId,
    listingId: listing.id,
    status: ListingInquiryStatus.COMPLETED,
    source: ListingInquirySource.ORGANIC,
    user: {
      id: currentUser?.id,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      emailAddress: currentUser?.emailAddress,
    },
  }

  return (
    <ApiForm
      onSubmit={createListingInquiry}
      skipIfIsNotDirty={false}
      beforeValidation={{
        run: async ({ resolve }) => {
          checkAuthentication({ shouldThrowError: true, triggerSubmit: resolve })
        },
      }}
      formOptions={{ resolver: yupResolver(AuthenticatedApi.Schema.createInquirySchema, { defaultValues }) }}
      formName='ListingInquiryForm'
      apiOptions={{
        onSuccess: async (inquiry) => {
          setListingInquiry(inquiry)
          setInquirySubmitted(true)
          setOpenInquiryCardModal && setOpenInquiryCardModal(false)
        },
      }}
    >
      {({ isSuccess, isLoading }) => (
        <Flex>
          {!isUnlisted && <ListingContent listing={listing} roommates={roommates} isRentedOut={isRentedOut} />}
          {shouldShowInquiryCard && (
            <ListingInquiryCard listing={listing} isSuccess={isSuccess} isLoading={isLoading} />
          )}
        </Flex>
      )}
    </ApiForm>
  )
}

const ListingScreen: React.FC = () => {
  const { t } = useTranslation(['main-rentalListing'])
  const { data: listing } = usePublicListingContext()
  const { user: currentUser } = useAuthenticationContext()
  const location = useLocationFromPath()

  const leadPicture = listing?.pictures[0]
  const addressString = listing ? addressFormatter.printShortAddress(listing.address) : undefined
  const listingStatus = getListingAvailability(listing)
  const userIsOperator = listing ? isListingOperator(currentUser, listing) : false
  const isRentedOut = listingStatus === 'rentedOut'
  const isUnlisted = listingStatus === 'unlisted'
  const hasBanner = userIsOperator || isUnlisted || isRentedOut

  return (
    <PageLayout breadcrumbItems={getListingBreadcrumbItemsFromLocation(t, location, listing)}>
      <PageMetaTags
        noIndex={listing ? false : true}
        subtitle={`${addressString}${listing ? ` ${listing.id}` : ''}`}
        description={listing ? `${listing.description} - ${addressString}` : undefined}
        imageUrl={leadPicture?.url}
      />
      <Flex flexDirection='column' gap={8}>
        {hasBanner && (
          <Flex flexDirection='column' gap={2}>
            {userIsOperator && <OperatorComponent listingId={listing?.id} status={listing?.status} />}
            {isUnlisted && <ListingBanner color={'alert'} text={t('listingRemovedByPoster')} />}
            {isRentedOut && <ListingBanner color={'info'} text={t('listingAllRentedOut')} />}
          </Flex>
        )}
        <ListingInquiryContextProvider listing={listing}>
          <ListingScreenContent
            userIsOperator={userIsOperator}
            listing={listing}
            isRentedOut={isRentedOut}
            isUnlisted={isUnlisted}
          />
        </ListingInquiryContextProvider>

        <ListingScreenDivider />
        <SimilarListings listingId={listing?.id} location={location} />
        <NeighbourhoodComponent
          city={location.citySlug}
          country={location.countryCode}
          state={location.state.stateCode}
        />
      </Flex>
    </PageLayout>
  )
}

export { ListingScreen }
