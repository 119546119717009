import React from 'react'

import { InvoiceContent, useInvoiceContext } from '@guiker/payment-app-components'
import { PageLayout } from '@guiker/react-framework'

import { useAuthenticationContext } from '../../hooks'

export const ViewInvoiceScreen: React.FC = () => {
  const { user } = useAuthenticationContext()
  const { isLoading } = useInvoiceContext()

  return (
    <PageLayout forceTakeover maxWidth={900} isLoading={isLoading}>
      <InvoiceContent user={user} />
    </PageLayout>
  )
}
