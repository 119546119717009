import React, { useEffect, useLayoutEffect } from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { BookingPayment, hasRentPaymentsPlanSupport } from '@guiker/booking-shared'
import { getPayInMethodLabels, PayInMethodModals, PayInMethodSelector } from '@guiker/payment-app-components'
import { PayInMethodContextProvider, usePayInMethodContext } from '@guiker/payment-context'
import { getSupportedCountryFromCurrency, PayInMethodType, SupportedCountries } from '@guiker/payment-shared'
import {
  ButtonContainer,
  ButtonWithLoader,
  Divider,
  Flex,
  Form,
  KeyValuePair,
  Note,
  P,
  PageLayout,
  SuccessDialog,
  useModal,
  useNavigate,
  useQueryMutation,
  useT,
  useTranslation,
} from '@guiker/react-framework'
import { compareDate, CountryCode, DateTime } from '@guiker/shared-framework'

import { PaymentBreakdown } from '../../components'
import { useAppContext, useTakeoverContext } from '../../hooks'

export const Content: React.FC<{ bookingPayment: BookingPayment }> = ({ bookingPayment }) => {
  const { booking, bookingUser, apiClient } = useBookingContext()
  const { t } = useTranslation('common')
  const { tBase } = useT({ domain: 'bookingRentPayment' })
  const { selected: reconnected, modalManager } = usePayInMethodContext()
  const { onClose: closeTakeover } = useTakeoverContext()
  const { isOpen, openModal, closeModal } = useModal()

  const isCABooking = booking.listing.address.countryCode === CountryCode.CA
  const isReconnected = isCABooking && hasRentPaymentsPlanSupport({ bookingPayment })

  const { useMutation } = useQueryMutation({ queryKey: ['ReconnectEFT', booking.id], apiClient })
  const { mutate: onClick, isLoading } = useMutation(
    () =>
      apiClient.reconnectCanadianPaymentMethod({
        pathParams: { bookingId: booking.id, applicantUserId: bookingUser.userId },
        payload: { payInMethodId: reconnected?.id, payInMethodType: PayInMethodType.DIRECT_DEBIT_PAD },
      }),
    { onSuccess: () => openModal() },
  )

  useEffect(() => {
    isReconnected && openModal()
  }, [isReconnected])

  useLayoutEffect(() => {
    modalManager.buttonType.setType('button')
  }, [])

  const info = getPayInMethodLabels({ payInMethod: reconnected, t, region: SupportedCountries.Canada })
  const onClose = () => {
    closeModal()
    closeTakeover()
  }

  return (
    <>
      {!isReconnected && (
        <ButtonContainer>
          <ButtonWithLoader type='button' onClick={() => onClick()} isLoading={isLoading} disabled={!reconnected}>
            <P color='textSecondary'>{tBase('actions.submit')}</P>
          </ButtonWithLoader>
        </ButtonContainer>
      )}
      <SuccessDialog
        open={isOpen}
        text={t('success.title')}
        onClose={onClose}
        subtitle={t('success.description')}
        buttonProps={{ onClick: onClose, children: t('close') }}
      >
        <KeyValuePair keyName={t('success.with')} value={info.brandName} />
      </SuccessDialog>
    </>
  )
}

export const ReconnectEFT: React.FC = () => {
  const { booking, bookingStatus } = useBookingContext()
  const { bookingPayment } = useAppContext()
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'screens.reconnectEFT' })
  const navigate = useNavigate()

  const region = getSupportedCountryFromCurrency(bookingPayment.total.currency)
  const isCABooking = booking.listing.address.countryCode === CountryCode.CA
  const isOngoing = compareDate(DateTime.local()).isBefore(booking.info.period.to)
  const canReconnect = isCABooking && isOngoing && bookingStatus.isBooked

  useEffect(() => {
    !canReconnect && navigate('/')
  }, [canReconnect])

  if (!canReconnect) return <></>

  return (
    <PageLayout maxWidth={900} hasButtonContainer title={tMain('title')}>
      <PayInMethodContextProvider region={region} allowedTypes={[PayInMethodType.DIRECT_DEBIT_PAD]}>
        <Flex flexDirection='column' gap={3}>
          <Note statusColor='alabaster' title={tMain('note.title')}>
            <P>{tMain('note.content')}</P>
          </Note>
          <Form formName='BookingPaymentReconnectEFT'>
            <PayInMethodSelector />
          </Form>
          <Divider />
          <div>
            <PaymentBreakdown />
          </div>
          <Content bookingPayment={bookingPayment} />
        </Flex>
        <PayInMethodModals />
      </PayInMethodContextProvider>
    </PageLayout>
  )
}
