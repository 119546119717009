import React from 'react'

import { AnyColor, Chip } from '@guiker/react-framework'

export const ActivityChip: React.FC<{
  content: string
  color?: AnyColor | string
  variant?: 'filled' | 'outlined' | 'light'
}> = ({ content, color, variant = 'filled' }) => {
  return (
    <Chip size='smaller' color={color} borderRadiusStyle='semi-round' variant={variant}>
      {content}
    </Chip>
  )
}
